import React, { useState, useEffect, useCallback } from 'react';
import { getFromServer, postToServer } from '../../../components/Common/requests';
import { showToast } from '@jobber/components/Toast';
import { getTableSelector, initialzeTable } from '../../../utils/manageTable';
import ConvertedJobsTable from '../../AdvanceJobs/Table/ConvertedJobsTable';
import JobFilters from '../../AdvanceJobs/Table/Filters/JobFilters';
import { visitColumnVisibility, visitVisibleColumns, visitWidthData } from '../../../components/Data/data';
import LoadData from '../LoadData/LoadData';
import { useVisitFilters } from '../FilterQuery/VisitFilterQuery';
import ShowAppliedFilters from '../../../components/Common/ShowAppliedFilters';
import { Option, Select } from '@jobber/components/Select';
import Pagination from '../../../components/Common/Pagination';
import LockerButton from '../../../utils/LockerButton';
import CompleteVisit from '../components/Buttons/CompleteVisit';
import BulkTag from '../components/Buttons/BulkTag';
import VisitFilters from '../Filters/ViisitFilters';
import AssignedUsers from './AssignedUsers';

function Table() {
	const { buildQuery, filters, updateFilter } = useVisitFilters();

	// for user settings
	const [dragtableState, setDragtableState] = useState({});
	useEffect(() => {
		setTimeout(async () => {
			await initialzeTable(setDragtableState, 'visitSettings', getTableSelector('jobs'));
		}, 1000);
	}, []);
	// -----------------

	const [loading, setLoading] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [data, setData] = useState([]);
	const [totalPage, setTotalPage] = useState(1);
	const [entries, setEntries] = useState(null);
	const [pageLoad, setPageLoad] = useState(true);
	const [limit, setLimit] = useState(10);

	const fetchData = async () => {
		setLoading(true);
		const queryString = buildQuery();
		const result = await getFromServer(`job-visits?${queryString}`);
		if (result.status) {
			setData(result.visits);
			setEntries(result.totalVisits);
			setTotalPage(result.totalPages);
			setLoading(false);
			setCurrentPage(filters?.page?.[0] || currentPage)
			console.log(filters?.page?.[0])
		}
	};

	useEffect(() => {
		fetchData();
	}, [filters]);

	// search debbouncing
	const [search, setSearch] = useState('');
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(search);

	const updateFilters = useCallback((filterName, value) => {
		updateFilter(filterName, value);
	}, []);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedSearchTerm(search);
		}, 500);

		return () => {
			clearTimeout(handler);
		};
	}, [search]);

	useEffect(() => {
		if (!pageLoad) {
			updateFilters('searchTerm', debouncedSearchTerm);
		} else {
			setPageLoad(false);
		}
	}, [debouncedSearchTerm]);

	const getApiUrl = () => {
		const queryString = buildQuery();
		return `delete-visits?${queryString}`;
	};

	// for close jobs table for reopneing jobs
	const updateData = (row, type) => {
		if (type == 'remove') {
			const updatedData = data?.filter((r) => r._id != row._id);
			setData(updatedData);
		}
	};

	return (
		<div>
			<div className="mb-4 d-flex justify-content-between align-items-center">
				<ShowAppliedFilters page={'visits'} />
				<div className="d-flex mt-3 gap-2 justify-content-end">
					<BulkTag reload={fetchData} />
					{filters?.isComplete?.[0] == 'false' ? <CompleteVisit reload={fetchData} /> : ''}
					<LockerButton
						method="DELETE"
						reload={fetchData}
						btnText="Delete Visits"
						apiUrl={getApiUrl()}
						successMsg={'Visits deleted successfully'}
					/>
					<LoadData reload={fetchData} />
				</div>
			</div>
			<div className="mt-4 mb-2 align-items-center d-flex gap-2 justify-content-between">
				<div className="d-flex visit__select align-items-center justify-content-center gap-2">
					<p className="pt-3">Show</p>
					<Select
						size="small"
						onChange={(e) => {
							updateFilter('limit', e);
							setLimit(e);
						}}
						value={limit}
					>
						<Option value="10">10</Option>
						<Option value="20">20</Option>
						<Option value="50">50</Option>
					</Select>
					<p className="pt-3">entries</p>
				</div>
				<div className="d-flex flex-wrap gap-2 justify-content-end align-items-center">
					<VisitFilters visits />
				</div>
			</div>
			<AssignedUsers /> 
			<ConvertedJobsTable
				columnsStateData={visitVisibleColumns}
				columnsHeader={visitColumnVisibility}
				columnsWidth={visitWidthData}
				visits
				sync={() => {}}
				currentPage={'jobs'}
				dragtableState={dragtableState}
				setDragtableState={setDragtableState}
				search={search}
				setSearch={setSearch}
				loading={loading}
				data={data}
				updateData={updateData}
			/>
			{data?.length <= 0 && !loading ? <div className="text-center">No data found</div> : ''}
			<div className="d-md-flex justify-content-between">
				<div className="">
					<p> {entries} entries</p>
				</div>

				{totalPage <= 1 ? (
					''
				) : (
					<div className="">
						<Pagination
							currentPage={currentPage}
							totalPages={totalPage}
							onPageChange={(page) => {
								setCurrentPage(page);
								updateFilter('page', page);
								window.scrollTo(0, 0);
							}}
						/>
					</div>
				)}
			</div>
		</div>
	);
}

export default Table;
