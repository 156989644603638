import React, { useEffect, useState } from 'react';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import Index from './pages/Index/Index';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router';
import Signup from './pages/Authentication/Signup';
import Protect from './pages/Authentication/Protect';
import { useLocation } from 'react-router-dom';
import Signin from './pages/Authentication/Signin';
import { getFromServer } from './components/Common/requests';
import { setLoginUser } from './store/userSlice';
import Loader from './components/Common/Loader';
import { showToast } from '@jobber/components/Toast';
import InitialSocket from './socket/InitialSocket';
import AdvanceQuotes from './pages/AdvanceQuotes/AdvanceQuotes';
import DetailsPage from './pages/AdvanceQuotes/DetailsPage';
import Settings from './pages/Settings/Settings';
import AdvanceNotes from './pages/AdvanceNotes/AdvanceNotes';
import PrivacyPolicy from './pages/Public/PrivacyPolicy';
import UserAgreement from './pages/Public/UserAgreement';
import AdvanceJobs from './pages/AdvanceJobs/AdvanceJobs';
import ApprovedQuotes from './pages/AdvanceJobs/ApprovedQuotes';
import AdvanceVisits from './pages/AdvanceVisits/AdvanceVisits';
import Dashboard from './pages/Dashboard/Dashboard';
import { AutomationFilterProvider } from './pages/AdvanceJobs/FilterQuery/AutomationFilterQuery';

function App() {
  const layout = useSelector((store) => store.layout);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoginOrSignupPage =
    location.pathname === '/login' ||
    location.pathname === '/signup' ||
    location.pathname === '/privacy-policy' ||
    location.pathname === '/user-license-agreement';

  if (isLoginOrSignupPage) {
    document.body.classList.remove('body-pd');
  }

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const storage = JSON.parse(localStorage.getItem('authUser'));
    const queryParams = new URLSearchParams(window.location.search);
    const userConnection = queryParams.get('userConnection');
    const userQBConnection = queryParams.get('userQBConnection');

    if (storage) {
      setIsLoading(true);
      const fetchResult = async () => {
        const result = await getFromServer('user');
        console.log(result)
        if (result.status) {
          let item = JSON.parse(localStorage.getItem('authUser'));
          item['data'] = result.data;
          localStorage.setItem('authUser', JSON.stringify(item));
          dispatch(setLoginUser(item));
          if (userConnection === 'true') {
            navigate('/');
            showToast({
              message: 'All tags will be fetched within 15 minutes',
              variation: 'success'
            });
          }
          if (userQBConnection === 'true') {
            navigate('/');
            showToast({
              message: 'Quickbooks connected successfully',
              variation: 'success'
            });
          }
        } else {
          showToast({
            message: result.message || result.error,
            variation: 'error'
          });
        }
        setIsLoading(false);
      };
      if (!isLoginOrSignupPage) {
        fetchResult();
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <>
        <AutomationFilterProvider>

          <InitialSocket />
          {!isLoginOrSignupPage && <Header />}
          {layout.showSidebar && !isLoginOrSignupPage ? <Sidebar /> : ''}

          <div className={`${!isLoginOrSignupPage ? 'height-100  pt-3' : ''} `}>
            <Routes>
              <Route index element={<Protect element={<Navigate to={'/clients'} />} />} exact />
              <Route path="/dashboard" element={<Protect element={<Dashboard />} />} />
              <Route path="/clients" element={<Protect element={<Index />} />} />
              <Route path="/settings" element={<Protect element={<Settings />} />} />
              <Route path="/recycle-quotes" element={<Protect element={<AdvanceQuotes />} />} />
              <Route path="/advance-quotes" element={<Protect element={<AdvanceQuotes />} />} />
              <Route path="/advance-quotes/:name/:id" element={<Protect element={<DetailsPage />} />} />
              <Route path="/advance-notes" element={<Protect element={<AdvanceNotes />} />} />
              <Route path="/advance-visits" element={<Protect element={<AdvanceVisits />} />} />
              <Route path="/job-automation" element={<Protect element={<AdvanceJobs />} />} />
              <Route path="/approved-quotes" element={<Protect element={<ApprovedQuotes />} />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Signin />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/user-license-agreement" element={<UserAgreement />} />
            </Routes>
          </div>
        </AutomationFilterProvider>
      </>
    );
  }
}

export default App;
